@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --game-width: 300px;
  --app-scale: 1;
}

* {
  touch-action: manipulation;
  user-select: none;
}

body {
  background-color: #6b7280;
}

a {
  -webkit-tap-highlight-color: transparent;
}

#game {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
}
/*
#game-content canvas{
  border-bottom: 2px solid #fff;
} */

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.scrollbar-hide {
  -ms-overflow-style: none;
  scrollbar-width: none;
}


@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes dialog-recipe {
  from {
    opacity: 0;
    transform: translateY(-8px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes dialog-from-bottom{
  from {
    opacity: 0.4;
    transform: translateY(4px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.info-dialog-animation {
  animation-name: dialog-from-bottom;
  animation-duration: 600ms;
}

.recipe-dialog-animation {
  animation-name: dialog-recipe;
  animation-duration: 600ms;
}

.fade-in-animation {
  animation-name:fade-in;
  animation-duration: 600ms;
}
